import React from 'react';
import { Box, Heading, Text, VStack, Divider } from '@chakra-ui/react';

const PrivacyPolicy: React.FC = () => {
  return (
    <Box maxW="800px" mx="auto" py={10} px={4}>
      <Heading as="h2" fontSize="3xl" mb={6} mt={20} textAlign="center" color="primary.600">
        Política de Privacidad
      </Heading>
      <VStack spacing={6} align="stretch">
        <Heading as="h3" fontSize="xl" color="primary.500">
          1. ¿Quién es el responsable del tratamiento de sus datos?
        </Heading>
        <Text fontSize="md" color="gray.600">
          El responsable del tratamiento de sus datos personales es [Nombre del Responsable], con N.I.F. B-[19992155] y domicilio social en la Calle Sierra Morena 8, El Padrón, Estepona, Málaga, España.<br />
          Le informamos que la titularidad del dominio de nuestra web https://[token-meet].com/ (en adelante el “Sitio Web”), es de [Nombre de la Empresa] S.L.<br />
          El acceso y/o uso al Sitio Web le atribuye la condición de Usuario (en adelante, el "Usuario"), e implica la aceptación, desde dicho acceso y/o uso, de la presente Política de Privacidad.<br />
          El Usuario podrá contactar con [Nombre de la Empresa] S.L. en la siguiente dirección de correo electrónico: info@token-meet.com.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          2. ¿Por qué TokenMeet está legitimado para llevar a cabo el tratamiento de datos?
        </Heading>
        <Text fontSize="md" color="gray.600">
          TokenMeet está legitimado para tratar sus datos para poder llevar a cabo la prestación de sus servicios.<br />
          TokenMeet se toma muy en serio la protección de su privacidad y de sus datos personales. Por lo tanto, su información personal se conserva de forma segura y se trata con el máximo cuidado.<br />
          La presente Política de Privacidad regula el acceso y el uso al servicio (en adelante, el “Servicio”) que TokenMeet pone a disposición del Usuario interesado en los servicios y contenidos alojados en el Sitio Web.<br />
          En este sentido, las bases de legitimación utilizadas por TokenMeet para el tratamiento de los datos de los Usuarios son:<br />
          • La ejecución contractual.<br />
          • El consentimiento de los Usuarios al tratamiento de sus datos personales para las finalidades especificadas en cada caso.<br />
          • El interés legítimo.<br />
          Por lo que respecta a este último, el Usuario podrá retirar el consentimiento en cualquier momento mandando un mensaje a la siguiente dirección de correo electrónico: info@token-meet.com, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          3. ¿Cuáles son las finalidades del tratamiento de sus datos de carácter personal por parte de TokenMeet?
        </Heading>
        <Text fontSize="md" color="gray.600">
          Sus datos de carácter personal recabados por TokenMeet podrán ser utilizados para las siguientes finalidades:<br />
          • La prestación de los servicios ofrecidos por TokenMeet.<br />
          • El envío de comunicaciones comerciales y/o Newsletter de TokenMeet sobre sus servicios mediante carta, teléfono, correo electrónico, SMS/MMS, o por otros medios de comunicación electrónica equivalentes.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          4. Veracidad de los datos facilitados por los Usuarios y datos de menores
        </Heading>
        <Text fontSize="md" color="gray.600">
          El Usuario garantiza que los datos personales facilitados son veraces y se hace responsable de comunicar a TokenMeet cualquier modificación de los mismos. El Usuario responderá, en cualquier caso, de la veracidad de los datos facilitados, reservándose TokenMeet el derecho a excluir de los Servicios registrados a todo Usuario que haya facilitado datos falsos, sin perjuicio de las demás acciones que procedan en Derecho.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          5. Conservación de los datos
        </Heading>
        <Text fontSize="md" color="gray.600">
          TokenMeet conservará los datos personales de los usuarios únicamente durante el tiempo necesario para la realización de las finalidades para las que fueron recogidos, mientras el Usuario no revoque los consentimientos otorgados. Posteriormente, en caso de ser necesario, TokenMeet mantendrá la información bloqueada los plazos legalmente establecidos.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          6. Derechos del Usuario en relación con sus datos
        </Heading>
        <Text fontSize="md" color="gray.600">
          El Usuario tiene derecho a:<br />
          • Acceder a sus datos personales.<br />
          • Solicitar la rectificación de los datos inexactos.<br />
          • Solicitar su supresión.<br />
          • Solicitar la limitación del tratamiento de sus datos.<br />
          • Oponerse al tratamiento de sus datos.<br />
          • Solicitar su portabilidad.<br />
          • No ser objeto de decisiones individuales automatizadas.<br />
          El Usuario puede ejercer todos estos derechos en la siguiente dirección de email info@token-meet.com indicando el motivo de su petición y aportando una copia de su D.N.I.<br />
          El Usuario puede también enviar su petición por correo ordinario a la siguiente dirección:<br />
          TokenMeet<br />
          Calle Sierra Morena 8, El Padrón, Estepona, Málaga, España.<br /><br />
          Sin perjuicio de cualquier otro recurso administrativo o acción judicial, el Usuario tendrá derecho a presentar una reclamación ante una Autoridad de Control, en particular en el Estado miembro en el que tenga su residencia habitual, lugar de trabajo o lugar de la supuesta infracción, en caso de que considere que el tratamiento de sus datos personales no es adecuado a la normativa, así como en el caso de no ver satisfecho el ejercicio de sus derechos. La autoridad de control ante la que se haya presentado la reclamación informará al reclamante sobre el curso y el resultado de la reclamación.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          7. Seguridad de los datos
        </Heading>
        <Text fontSize="md" color="gray.600">
          La protección de la privacidad y los datos personales de los Usuarios es muy importante para TokenMeet. Por lo tanto, TokenMeet hace todo lo que está en su mano para impedir que sus datos se utilicen de forma inadecuada, permitiendo el acceso a los mismos únicamente a personal autorizado.<br />
          TokenMeet mantiene los niveles de seguridad de protección de datos personales conforme a la normativa aplicable y ha establecido todos los medios técnicos a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos que el Usuario facilite a través del Sitio Web, sin perjuicio de informarle de que las medidas de seguridad en Internet no son inexpugnables.<br />
          TokenMeet se compromete a cumplir con el deber de secreto y confidencialidad respecto de los datos personales de acuerdo con la legislación aplicable.<br /><br />
          En aquellos servicios de la web que precisen registrarse como Usuario, se deberá elegir una contraseña. El Usuario es el responsable de mantener la confidencialidad de esta contraseña, así como de todas las actividades que ocurran en la sesión iniciada con su nombre y contraseña.<br />
          El Usuario se compromete a notificar a TokenMeet a la mayor brevedad el uso no autorizado de su nombre de Usuario y/o contraseña o cualquier otro fallo en la seguridad. TokenMeet no será responsable por los daños o pérdidas que se pudieran originar debido al no cumplimiento de esta obligación por parte del Usuario.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          8. Cambios
        </Heading>
        <Text fontSize="md" color="gray.600">
          TokenMeet se reserva el derecho de revisar su Política de Privacidad en el momento que considere oportuno. Por esta razón, le rogamos que compruebe de forma regular esta Política de Privacidad para leer la versión más reciente de la misma.<br />
          No obstante, cualquier cambio que se produzca en la presente Política de Privacidad se comunicará al Usuario.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          9. Links a páginas web
        </Heading>
        <Text fontSize="md" color="gray.600">
          La Plataforma de TOKENMEET podría contener links a páginas web de compañías y entidades de terceros.<br />
          TokenMeet no puede hacerse responsable de la forma en la que estas compañías tratan la protección de la privacidad y de los datos personales, por lo que le aconsejamos que lea detenidamente las declaraciones de Política de Privacidad de estas páginas web que no son titularidad de TokenMeet con relación al uso, procesamiento y protección de datos personales.<br />
          Las condiciones que ofrecen estas páginas web pueden no ser las mismas que las que ofrece TokenMeet.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          10. Preguntas
        </Heading>
        <Text fontSize="md" color="gray.600">
          Si tienen alguna pregunta sobre esta Política de Privacidad o el tratamiento de sus datos rogamos que se ponga en contacto con TokenMeet mediante correo electrónico dirigido a la siguiente dirección de correo electrónico: info@token-meet.com.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          11. Aceptación y Consentimiento
        </Heading>
        <Text fontSize="md" color="gray.600">
          El Usuario declara haber sido informado de las condiciones sobre protección de datos personales, aceptando y consintiendo el tratamiento de estos por parte de TokenMeet en la forma y para las finalidades indicadas en la presente Política de Privacidad.
        </Text>
      </VStack>
    </Box>
  );
};

export default PrivacyPolicy;
