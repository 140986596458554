import imageNews1 from '../assets/images/news1.webp';  
import imageNews2 from '../assets/images/news2.jpeg';  
import imageNews3 from '../assets/images/news3.jpg';  

export type EducationResource = {
    id: string;
    title: string;
    type: 'video' | 'news';
    image?: string;  // Imagen para noticias
    shortDescription?: string;  // Descripción corta para noticias
    videoUrl?: string;  // URL para el video
    category: string;  // Categoría del recurso
    newsUrl?: string;  // URL para la noticia
};

function getYouTubeThumbnail(videoUrl: string): string {
    let videoId = '';
    if (videoUrl.includes('youtu.be')) {
        videoId = videoUrl.split('youtu.be/')[1];
    } else if (videoUrl.includes('v=')) {
        videoId = videoUrl.split('v=')[1];
    }

    if (videoId.includes('&')) {
        videoId = videoId.split('&')[0];
    }

    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
}

export const educationResourcesConfig: EducationResource[] = [
    {
        id: '1',
        title: 'Introduction to Blockchain',
        type: 'video',
        videoUrl: 'https://youtu.be/H-2UR1IbE60',
        category: 'Blockchain',
        image: getYouTubeThumbnail('https://youtu.be/H-2UR1IbE60') 
    },
    {
        id: '2',
        title: 'Cryptocurrency Market Analysis',
        type: 'news',
        image: imageNews1,
        newsUrl: 'https://cincodias.elpais.com/mercados-financieros/2024-07-09/la-tokenizacion-el-futuro-de-un-dinero-que-ya-es-digital.html',
        shortDescription: 'An in-depth analysis of the current cryptocurrency market trends.',
        category: 'Cryptocurrency',
    },
    {
        id: '3',
        title: 'How to Use Ethereum',
        type: 'video',
        videoUrl: 'https://youtu.be/JOyOt-WmCNA',
        category: 'Ethereum',
        image: getYouTubeThumbnail('https://youtu.be/JOyOt-WmCNA') 
    },
    {
        id: '4',
        title: 'The Future of Decentralized Finance',
        type: 'news',
        image: imageNews2,
        newsUrl: 'https://www.unknowngravity.com/articulos/tokenizacion-de-inmuebles',
        shortDescription: 'Exploring the potential of decentralized finance in the coming years.',
        category: 'DeFi',
    },
    {
        id: '5',
        title: 'Security Token Offerings (STO)',
        type: 'video',
        videoUrl: 'https://youtu.be/b8ftIF4DuL4',
        category: 'STO',
        image: getYouTubeThumbnail('https://youtu.be/b8ftIF4DuL4') 
    },
    {
        id: '6',
        title: 'Real Estate Tokenization',
        type: 'news',
        image: imageNews3,
        newsUrl: 'https://www.nwc10lab.com/como-funciona-y-participar-en-una-sto/',
        shortDescription: 'A deep dive into real estate tokenization.',
        category: 'Real Estate',
    },
    
];
