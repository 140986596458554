import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, VStack, Stat, StatLabel, StatNumber, useBreakpointValue } from '@chakra-ui/react';
import JoinTokenMeetButton from '../buttons/JoinTokenMeetButton'; // Importa el botón con el popup
import Hero1 from '../../assets/images/Hero1.png';
import Hero2 from '../../assets/images/Hero2.png';
import Hero3 from '../../assets/images/Hero3.png';
import Hero4 from '../../assets/images/Hero4.png';
import Hero5 from '../../assets/images/Hero5.png';

const HeroSection: React.FC = () => {
  const images = [Hero1, Hero2, Hero3, Hero4, Hero5];

  const [bgIndex, setBgIndex] = useState(0);
  const [counters, setCounters] = useState({
    raised: 0,
    donations: 0,
    projects: 0,
    members: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    const targetValues = { raised: 256000, donations: 350, projects: 8, members: 328 };
    const incrementSpeed = 50;

    const interval = setInterval(() => {
      setCounters((prevCounters) => ({
        raised: Math.min(prevCounters.raised + 50000, targetValues.raised),
        donations: Math.min(prevCounters.donations + 5, targetValues.donations),
        projects: Math.min(prevCounters.projects + 1, targetValues.projects),
        members: Math.min(prevCounters.members + 100, targetValues.members),
      }));
    }, incrementSpeed);

    return () => clearInterval(interval);
  }, []);

  // Define the number of columns based on the screen size
  const gridTemplateColumns = useBreakpointValue({ base: "1fr 1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr 1fr" });

  return (
    <Box
      h="100vh"
      position="relative"
      bgImage={`url(${images[bgIndex]})`}
      bgSize="cover"
      bgPosition="center"
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      textAlign="center"
      transition="background-image 1.5s ease-in-out"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bg: "rgba(0, 0, 0, 0.4)", // Filtro oscuro con opacidad
        zIndex: 0,
      }}
    >
      <VStack spacing={4} zIndex={1} mb={10}>
        <Heading
          as="h1"
          size="2xl"
          bgGradient="linear(100deg, #0ef1fa, #2ba1ce)"
          bgClip="text"
          fontWeight="700"
          fontFamily="Impact"  // Impact solo para este h1
          lineHeight="shorter"
        >
          Humanizamos
          <br />
          la tecnología
        </Heading>
        <Text fontSize="2xl" color="gray.200" fontWeight="700">
          Tokenización accesible para todos
        </Text>
        {/* Único botón que abrirá el popup */}
        <JoinTokenMeetButton />
      </VStack>

      <Box
        display="grid"
        gridTemplateColumns={gridTemplateColumns}
        gap={8}
        maxW="container.lg"
        mx="auto"
        mt={10}
      >
        <Stat textAlign="center">
          <StatNumber fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}>
            {counters.raised.toLocaleString()}€
          </StatNumber>
          <StatLabel>Recaudado</StatLabel>
        </Stat>
        <Stat textAlign="center">
          <StatNumber fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}>
            {counters.donations}
          </StatNumber>
          <StatLabel>Donaciones</StatLabel>
        </Stat>
        <Stat textAlign="center">
          <StatNumber fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}>
            {counters.projects}
          </StatNumber>
          <StatLabel>Proyectos</StatLabel>
        </Stat>
        <Stat textAlign="center">
          <StatNumber fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}>
            {counters.members}
          </StatNumber>
          <StatLabel>Miembros</StatLabel>
        </Stat>
      </Box>
    </Box>
  );
};

export default HeroSection;
