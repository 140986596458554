import React, { useState } from 'react';
import { Box, Heading, Grid, Select } from '@chakra-ui/react';
import ProjectCard from '../components/cards/ProjectCard';
import { Project } from '../types/projectTypes';

interface ProjectsPageProps {
  projectsConfig: {
    activeProjects: Project[];
    upcomingProjects: Project[];
    studyProjects: Project[];
  };
}

const ProjectsPage: React.FC<ProjectsPageProps> = ({ projectsConfig }) => {
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  
  const { activeProjects, upcomingProjects, studyProjects } = projectsConfig;

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  const getFilteredProjects = () => {
    let projects: Project[] = [];

    switch (selectedStatus) {
      case 'upcomingProjects':
        projects = upcomingProjects;
        break;
      case 'studyProjects':
        projects = studyProjects;
        break;
      case 'all':
      default:
        projects = [...activeProjects, ...upcomingProjects, ...studyProjects];
    }

    if (selectedCategory === 'all') {
      return projects;
    }

    return projects.filter(project => project.category === selectedCategory);
  };

  return (
    <Box w="100%" py={10}>
      <Box maxW="1200px" mx="auto" py={10} px={4}>
        <Heading as="h2" fontSize="3xl" mb={6} mt={20} textAlign="center" color="primary.600">
          Todos los Proyectos Tokenizados por Nuestra Comunidad
        </Heading>

        <Box mb={6}>
          <Select
            placeholder="Selecciona un estatus"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            <option value="all">Todos los Estatus</option>
            <option value="activeProjects">Proyectos Activos</option>
            <option value="upcomingProjects">Proyectos Próximos</option>
            <option value="studyProjects">Proyectos de Estudio</option>
          </Select>

          <Select
            placeholder="Selecciona una categoría"
            mt={4}
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="all">Todas las Categorías</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Deportes">Deportes</option>
          </Select>
        </Box>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
          {getFilteredProjects().map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProjectsPage;
