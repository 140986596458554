import React from 'react';
import { Box, Heading, Text, Image, Link as ChakraLink } from '@chakra-ui/react';
import { EducationResource } from '../../config/educationResourcesConfig';

const NewsCard: React.FC<{ resource: EducationResource }> = ({ resource }) => {
  return (
    <Box
      borderRadius="8px"
      overflow="hidden"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      bg="white"
      transition="all 0.3s ease"
      _hover={{ transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }}
      width="100%"
      height="450px" // Altura total de la tarjeta
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Image
        src={resource.image}
        alt={resource.title}
        height="200px" // Ajuste de altura de la imagen para que coincida con las otras tarjetas
        objectFit="cover"
        width="100%"
        borderBottom="8px solid #0b4d78"
      />
      <Box p="24px" flexGrow={1}>
        <Heading fontSize="1.25rem" mb="8px" color="black"> {/* Título en color negro */}
          {resource.title}
        </Heading>
        <Text fontSize="0.875rem" color="#718096" mb="16px" flexGrow={1}>
          {resource.shortDescription}
        </Text>
      </Box>
      <Box mt="8px" textAlign="center" p="16px">
        <ChakraLink href={resource.newsUrl} fontWeight="bold" color="primary.500" isExternal>
          Ver noticia completa
        </ChakraLink>
      </Box>
    </Box>
  );
};

export default NewsCard;
