import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Flex,
  Link,
  Icon,
  SimpleGrid,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormHelperText,
  useToast,
  VStack,
  HStack, // Importar HStack
} from '@chakra-ui/react';
import { FaLinkedin } from 'react-icons/fa';

import image1 from '../../assets/images/pablo.png';
import image2 from '../../assets/images/Isabel.jpeg';
import image3 from '../../assets/images/Antonio.jpeg';
import image6 from '../../assets/images/Javier.jpg';
import image4 from '../../assets/images/Filiph.jpeg';
import image5 from '../../assets/images/Victor.jpg';
import image7 from '../../assets/images/Manuel.jpeg';
import image8 from '../../assets/images/Isidor.jpg';

import GradientButton from '../buttons/GradientButton';

const TeamSection: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formValues, setFormValues] = useState({
    name: '',
    surname: '',
    email: '',
    linkedin: '',
    resumeUrl: '', // Cambiado a URL del currículum
  });
  const toast = useToast();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const { name, surname, email, linkedin, resumeUrl } = formValues;

      if (!name || !surname || !email || !resumeUrl) {
        toast({
          title: 'Error',
          description: 'Por favor, completa todos los campos obligatorios.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const response = await fetch('https://api-token-meet-asjc3j0y3-victors-projects-b0ae82d4.vercel.app/api/application', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      });

      if (response.ok) {
        toast({
          title: 'Información enviada.',
          description: 'Gracias por tu interés. Nos pondremos en contacto contigo pronto.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: 'Error.',
          description: 'Hubo un problema al enviar tu información. Por favor, intenta de nuevo.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error.',
        description: 'Hubo un problema al enviar tu información. Por favor, intenta de nuevo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const teamMembers = [
    {
      name: 'Manuel Escudero',
      position: 'CEO',
      imageUrl: image7,
      linkedin: 'https://www.linkedin.com/in/manuel-escudero-3426461bb?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    },
    {
      name: 'Pablo Pérez Sánchez',
      position: 'CMO',
      imageUrl: image1,
      linkedin: 'https://www.linkedin.com/in/pablo-perez-sanchez-financeworld',
    },
    {
      name: 'Antonio Cardador',
      position: 'Abogado',
      imageUrl: image3,
      linkedin: 'https://www.linkedin.com/in/antoniocardador?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    },
    {
      name: 'Javier Jiménez',
      position: 'Marketing',
      imageUrl: image6,
      linkedin: 'https://www.linkedin.com/in/javier-jim%C3%A9nez-social-media-manager?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    },
    {
      name: 'Filiph Castillo Karlsson',
      position: 'Delegación Londres, Inglaterra',
      imageUrl: image4,
      linkedin: 'https://www.linkedin.com/in/filiphcastillokarlsson/',
    },
    {
      name: 'Isidoro Fernández Fernández',
      position: 'Collaborating Partner',
      imageUrl: image8,
      linkedin: '',
    },
    {
      name: 'Victor Pérez',
      position: 'CTO - Embajador de Andorra',
      imageUrl: image5,
      linkedin: 'https://ad.linkedin.com/in/victor-perez-1431b2242/es',
    },
  ];

  return (
    <Box maxW="1200px" mx="auto" py={10} px={4} textAlign="center">
      <Heading as="h2" fontSize="5xl" mb={20} color="primary.600">
        Nuestro Equipo
      </Heading>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
        {teamMembers.map((member, index) => (
          <Box key={index} p={4} textAlign="center">
            <Box
              width="full"
              maxWidth="300px"
              height="300px"
              borderRadius="md"
              overflow="hidden"
              mb={4}
              mx="auto"
              border="1px solid"
              borderColor="gray.200"
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img 
                src={member.imageUrl} 
                alt={member.name} 
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover' 
                }} 
              />
            </Box>
            <Flex direction="column" alignItems="center">
              <Flex align="center" mb={2} justify="center">
                <Heading as="h3" fontSize="lg" mr={2}>{member.name}</Heading>
                <Link href={member.linkedin} isExternal>
                  <Icon as={FaLinkedin} boxSize={5} color="primary.500" />
                </Link>
              </Flex>
              <Text fontSize="sm" color="gray.600">{member.position}</Text>
            </Flex>
          </Box>
        ))}
      </SimpleGrid>

      <Box mt={12} textAlign="center">
        <Heading as="h2" fontSize="2xl" mb={4}>
          <strong>Únete a nuestro equipo</strong>
        </Heading>
        <Text fontSize="lg" mb={6}>
          Si te apasiona la tecnología y ayudar a la gente, este es tu sitio.
        </Text>
        <HStack spacing={4} justify="center"> {/* Agrupar botones */}
          <GradientButton onClick={onOpen}>
            Solicitar Información
          </GradientButton>
          <GradientButton onClick={() => window.open('/projects', '_blank')}>
            Ver Proyectos
          </GradientButton>
        </HStack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white" borderRadius="md">
          <ModalHeader fontSize="2xl" fontWeight="bold" textAlign="center">
            Solicita Información
          </ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl id="name" isRequired>
                <FormLabel>Nombre</FormLabel>
                <Input
                  name="name"
                  value={formValues.name}
                  onChange={handleInputChange}
                  placeholder="Nombre"
                  bg="gray.700"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'gray.500' }}
                  focusBorderColor="blue.500"
                />
              </FormControl>
              <FormControl id="surname" isRequired>
                <FormLabel>Apellidos</FormLabel>
                <Input
                  name="surname"
                  value={formValues.surname}
                  onChange={handleInputChange}
                  placeholder="Apellidos"
                  bg="gray.700"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'gray.500' }}
                  focusBorderColor="blue.500"
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  bg="gray.700"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'gray.500' }}
                  focusBorderColor="blue.500"
                />
              </FormControl>
              <FormControl id="linkedin">
                <FormLabel>LinkedIn</FormLabel>
                <Input
                  name="linkedin"
                  value={formValues.linkedin}
                  onChange={handleInputChange}
                  placeholder="LinkedIn"
                  bg="gray.700"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'gray.500' }}
                  focusBorderColor="blue.500"
                />
              </FormControl>
              <FormControl id="resumeUrl" isRequired>
                <FormLabel>URL del Currículum</FormLabel>
                <Input
                  name="resumeUrl"
                  value={formValues.resumeUrl}
                  onChange={handleInputChange}
                  placeholder="Enlace al currículum"
                  bg="gray.700"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'gray.500' }}
                  focusBorderColor="blue.500"
                />
                <FormHelperText>
                  Proporciona un enlace a tu currículum en formato PDF o DOC.
                </FormHelperText>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button variant="outline" colorScheme="red" onClick={onClose}>
              Cerrar
            </Button>
            <GradientButton onClick={handleSubmit}>
              Enviar
            </GradientButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TeamSection;
