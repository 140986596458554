import React from 'react';
import { Box, Heading, Text, VStack, Divider } from '@chakra-ui/react';

const TermsAndConditions: React.FC = () => {
  return (
    <Box maxW="800px" mx="auto" py={10} px={4}>
    <Heading as="h2" fontSize="3xl" mb={6} mt={20} textAlign="center" color="primary.600">
        Términos y Condiciones
      </Heading>
      <VStack spacing={6} align="stretch">
        <Heading as="h3" fontSize="xl" color="primary.500">
          1. IDENTIFICACIÓN
        </Heading>
        <Text fontSize="md" color="gray.600">
          • Titular: (en adelante, “TOKENMEET”);<br />
          • Domicilio social: Calle Sierra Morena número 8, El Padrón, Estepona, Málaga<br />
          • Número de Identificación Fiscal: B19992155<br />
          • Registro Público:<br />
          • Correo electrónico: info@token-meet.com.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          2. OBJETO
        </Heading>
        <Text fontSize="md" color="gray.600">
          Mediante el presente documento se establecen las condiciones generales de contratación (en adelante, las “Condiciones de Contratación”) que regulan los términos en los que se basa el acceso a la prestación de los servicios de (i) información sobre tokenización de activos; (ii) creación de una wallet de activos tokenizados; (iii) acceso a entornos digitales privados de proyectos que incorporan tokens en su modelo de negocio (en adelante, los “Servicios”) por parte de los usuarios (en adelante, cuando corresponda, el “Usuario” o los “Usuarios”), ofrecidos a través de la plataforma TOKENMEET (en adelante, la “Plataforma”) accesible a través de la siguiente URL: www.token-meet.com.<br /><br />
          Asimismo, como parte de la prestación de los Servicios el Usuario también deberá aceptar los Términos y Condiciones de [Nombre del Tercero] (en adelante, “Condiciones del Tercero”), entidad de dinero electrónico con autorización administrativa. Dichas Condiciones del Tercero formarán parte indivisible del presente documento y complementarán y, en su caso, prevalecerán sobre lo establecido en las presentes Condiciones de Contratación.<br /><br />
          Cualquier cuestión que no hubiera sido expresamente prevista en las presentes Condiciones de Contratación se entenderá reservada a TOKENMEET, sin perjuicio de la aplicación de lo dispuesto en la normativa vigente. Asimismo, TOKENMEET se reserva el derecho a llevar a cabo modificaciones y/o actualizaciones en las presentes Condiciones de Contratación, de las que se informará previamente al Usuario para su aceptación o rechazo en caso de resultar sustanciales. En cualquier caso, se considerará que el Usuario acepta expresamente dichas modificaciones o actualizaciones si vuelve a contratar los servicios ofrecidos por TOKENMEET en la Plataforma.<br /><br />
          El hecho de seguir telemáticamente todos los pasos del mismo, supone la aceptación expresa de los presentes Condiciones de Contratación por parte del Usuario, teniendo la misma validez que la firma expresa de un contrato. De este modo, el Usuario reconoce ser una persona con capacidad suficiente para adquirir las obligaciones derivadas de sus actuaciones a través de la Plataforma, que ha leído previamente y comprende su contenido.<br /><br />
          En todo caso, para poder contratar con TOKENMEET, el Usuario debe ser mayor de edad (≥ dieciocho (18) años).
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          3. DESCRIPCIÓN DE LA PLATAFORMA
        </Heading>
        <Text fontSize="md" color="gray.600">
          TOKENMEET es una Plataforma que permite a los Usuarios entrar a un entorno en el que podrán aprender sobre tokenización de activos y descubrir compañías que operan con tokens. Así mismo podrán publicitar sus productos como solicitar el desarrollo de las campañas de marketing que deseen.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          4. REGLAS DE USO DE LA PLATAFORMA
        </Heading>
        <Text fontSize="md" color="gray.600">
          Serán de exclusiva responsabilidad del Usuario el acceso, navegación o utilización de la Plataforma y de los Servicios con fines ilegítimos y/o ilícitos. A modo enunciativo, pero no limitativo, queda prohibido:<br />
          1. Utilizar la Plataforma o los Servicios con fines ilícitos;<br />
          2. Usar la Plataforma con la finalidad de provocar daños, ineficiencias, interrupciones o defectos análogos en la funcionabilidad de la Plataforma o de estructuras electrónicas de TOKENMEET o cualquier tercero;<br />
          3. Utilizar la Plataforma para la transmisión de malware, virus o programas análogos o para publicar o difundir contenidos de carácter ofensivo, racista, denigrante o pornográfico que pudiera causar molestia en las personas;<br />
          4. Utilizar la plataforma para la transmisión de activos o cantidades de dinero que tengan un origen ilícito o que no haya sido declarado ante una autoridad competente en materia de control fiscal;<br />
          5. Usar la Plataforma en perjuicio de los intereses de TOKENMEET;<br />
          6. Registrarse en la Plataforma con una identidad falsa;<br />
          7. Utilizar la Plataforma con fines publicitarios, tales como el envío de spam u otras maneras semejantes;<br />
          8. Quebrantar las medidas de seguridad establecidas por TOKENMEET;<br />
          9. Llevar a cabo actuaciones tendentes a saturar el programa, perjudicando el buen funcionamiento de la Plataforma; y en cualquier caso<br />
          10. Usar la Plataforma de forma ilegal.<br /><br />
          En el caso de que el Usuario lleve a cabo cualquiera de las actuaciones descritas, TOKENMEET se reserva el derecho de adoptar las medidas que en derecho le amparan y que estime oportunas.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          5. RESPONSABILIDADES Y GARANTÍAS DE LA PLATAFORMA
        </Heading>
        <Text fontSize="md" color="gray.600">
          TOKENMEET no puede garantizar la fiabilidad, utilidad o veracidad de absolutamente toda la información contenida en la Plataforma, ni tampoco la utilidad o veracidad de los contenidos puestos a disposición de los usuarios a través de la misma.<br /><br />
          En consecuencia, TOKENMEET no garantiza ni se hace responsable de:<br />
          1. La continuidad de los contenidos de la Plataforma;<br />
          2. La ausencia de errores en dichos contenidos;<br />
          3. La ausencia de virus, troyanos, gusanos, bombas lógicas y/o demás componentes maliciosos o tecnológicamente dañinos en la Plataforma o en el servidor que la suministra;<br />
          4. La invulnerabilidad de la Plataforma y/o la imposibilidad de vulnerar las medidas de seguridad que se adopten en la misma;<br />
          5. La falta de utilidad o rendimiento de los contenidos de la Plataforma;<br />
          6. Los fallos de la Plataforma ocasionados por cualquier tipo de ataque a sus servidores o a los de terceros proveedores de servicios de la Plataforma, así como los fallos técnicos o de seguridad del sistema de cualquier de dichos proveedores que impidan el funcionamiento de la Plataforma;<br />
          7. Cualquier fallo técnico de cualquier tipo que dificulte, retrase o impida el correcto funcionamiento de la Plataforma;<br />
          8. Los daños o perjuicios que cause, a sí mismo o a un tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que TOKENMEET establece en la Plataforma o a través de la vulneración de los sistemas de seguridad.<br /><br />
          TOKENMEET declara que ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la técnica, para garantizar el funcionamiento de la Plataforma y reducir al mínimo los errores del sistema, tanto desde el punto de vista técnico como de los contenidos publicados en la Plataforma.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          6. PROCEDIMIENTO PARA EL USO DE LOS SERVICIOS
        </Heading>
        <Text fontSize="md" color="gray.600">
          6.1. - Acceso a la Plataforma<br />
          Los usuarios accederán a la Plataforma a través de la URL www.token-meet.com<br /><br />
          6.2. - Registro en la Plataforma<br />
          El Usuario se registra en la Plataforma cumplimentando el formulario de registro habilitado en la Plataforma.<br />
          El acceso y uso de la Plataforma supone que el Usuario acepta y se obliga a cumplir por completo las presentes Condiciones de Contratación, así como las instrucciones o recomendaciones que sean indicados en cada caso concreto a través de la Plataforma.<br />
          Para poder completar el proceso de contratación será necesario que el Usuario acepte la política de privacidad y cookies establecida por TOKENMEET.<br />
          El Usuario se compromete a proporcionar información veraz y completa en el proceso de registro y, en caso de modificar cualquier dato facilitado anteriormente, a mantener actualizada su información personal a través de los mecanismos habilitados en la Plataforma.<br /><br />
          6.3. - Contratación de los Servicios<br />
          El Usuario podrá contratar los Servicios a través de la Plataforma siguiendo los pasos establecidos en la misma. El proceso de contratación implicará:<br />
          1. Seleccionar los Servicios deseados;<br />
          2. Revisar la información de los Servicios y confirmar la contratación;<br />
          3. Proporcionar los datos de pago o realizar el proceso de pago a través del medio habilitado en la Plataforma;<br />
          4. Confirmar la aceptación de las Condiciones de Contratación y cualquier otro documento relevante.<br /><br />
          Una vez que el Usuario haya completado el proceso de contratación y pagado la contraprestación establecida, TOKENMEET enviará una confirmación de la contratación al Usuario a través de la dirección de correo electrónico facilitada.<br /><br />
          6.4. - Desistimiento y derecho de devolución<br />
          De acuerdo con la normativa vigente, el Usuario tiene derecho a desistirse de la contratación de los Servicios en un plazo de catorce (14) días naturales desde la fecha de contratación. El desistimiento deberá ser comunicado por el Usuario mediante un escrito dirigido a TOKENMEET a la dirección de correo electrónico proporcionada.<br /><br />
          6.5. - Reclamaciones<br />
          Cualquier reclamación relacionada con los Servicios deberá ser comunicada a TOKENMEET a través de los canales de contacto habilitados en la Plataforma.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          7. PROTECCIÓN DE DATOS
        </Heading>
        <Text fontSize="md" color="gray.600">
          TOKENMEET se compromete a tratar los datos personales del Usuario conforme a lo establecido en la normativa vigente en materia de protección de datos personales. Para obtener más información sobre cómo TOKENMEET trata los datos personales del Usuario, se puede consultar la Política de Privacidad disponible en la Plataforma.
        </Text>
        <Divider />

        <Heading as="h3" fontSize="xl" color="primary.500">
          8. LEGISLACIÓN APLICABLE Y JURISDICCIÓN
        </Heading>
        <Text fontSize="md" color="gray.600">
          Las presentes Condiciones de Contratación se regirán e interpretarán de acuerdo con la legislación española. Cualquier controversia que surja en relación con las presentes Condiciones de Contratación será sometida a los tribunales competentes de la ciudad de Málaga, España.
        </Text>
      </VStack>
    </Box>
  );
};

export default TermsAndConditions;
