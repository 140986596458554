import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  VStack,
  Link,
  Heading,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import GradientButton from '../buttons/GradientButton';
import axios from 'axios'; // Asegúrate de instalar axios

const ContactForm: React.FC = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: '',
    termsAccepted: false,
  });

  const navigate = useNavigate();
  const toast = useToast();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues(prevValues => ({ ...prevValues, termsAccepted: e.target.checked }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formValues.termsAccepted) {
      toast({
        title: 'Error.',
        description: 'Debes aceptar los términos y condiciones para enviar el formulario.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.post('https://api-token-meet-asjc3j0y3-victors-projects-b0ae82d4.vercel.app/api/contact', {
        name: formValues.name,
        email: formValues.email,
        message: formValues.message,
      });

      toast({
        title: 'Mensaje enviado.',
        description: 'Gracias por contactarnos. Nos pondremos en contacto contigo pronto.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setFormValues({ name: '', email: '', message: '', termsAccepted: false });
    } catch (error) {
      toast({
        title: 'Error.',
        description: 'Hubo un problema al enviar el formulario. Por favor, intenta de nuevo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box 
      w="100%" 
      py={10} 
      px={{ base: 6, md: 8, lg: 10 }} 
      bgGradient="radial-gradient(circle at 0% 80%, #0ef1fa 0%, #0b4d78 100%)" // Fondo azul degradado
      color="white" // Texto blanco
      textAlign="center"
    >
      <Box maxW="600px" mx="auto" py={10} px={4} textAlign="center"> {/* Ajusta maxW para hacer el formulario más estrecho */}
      <Heading as="h2" fontSize="5xl" mb={8}> {/* Ajusta el tamaño del encabezado y el margen inferior */}
          Contacta con nosotros
        </Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl id="name" isRequired>
              <FormLabel color="white">Nombre</FormLabel>
              <Input
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
                placeholder="Nombre"
                focusBorderColor="white" // Cambia el color del borde cuando el input está enfocado
                color="white" // Texto del input
                bg="gray.700" // Fondo del input
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel color="white">Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
                placeholder="Email"
                focusBorderColor="white" // Cambia el color del borde cuando el input está enfocado
                color="white" // Texto del input
                bg="gray.700" // Fondo del input
              />
            </FormControl>
            <FormControl id="message" isRequired>
              <FormLabel color="white">Mensaje</FormLabel>
              <Textarea
                name="message"
                value={formValues.message}
                onChange={handleInputChange}
                placeholder="Escribe tu mensaje..."
                focusBorderColor="white" // Cambia el color del borde cuando el input está enfocado
                color="white" // Texto del textarea
                bg="gray.700" // Fondo del textarea
                resize="vertical"
                minH="150px"
              />
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <Checkbox
                id="terms"
                isChecked={formValues.termsAccepted}
                onChange={handleCheckboxChange}
                colorScheme="teal" // Cambia el esquema de colores para el checkbox
                color="white"
              >
                He leído y acepto los{' '}
                <Link color="white" onClick={() => navigate('/terms-and-conditions')} isExternal>
                  Términos y condiciones
                </Link>
              </Checkbox>
            </FormControl>
            <GradientButton
              type="submit"
              isDisabled={!formValues.termsAccepted}
              width="auto"
              maxW="200px"
            >
              Enviar Mensaje
            </GradientButton>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default ContactForm;
