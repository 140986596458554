import React, { useState, useEffect } from 'react';
import { Box, Heading, Grid, Button } from '@chakra-ui/react';
import CharityCard from '../cards/CharityCard';  // Usa CharityCard en lugar de ProjectCard
import { Project } from '../../types/projectTypes'; // Asegúrate de que esta ruta sea correcta
import { fetchProjectsConfig } from '../../config/projectsConfig';
import GradientButton from '../buttons/GradientButton'; // Asegúrate de importar el botón

const CharitySection: React.FC = () => {
  const [charityProjects, setCharityProjects] = useState<Project[]>([]);
  const [visibleProjects, setVisibleProjects] = useState<number>(3);

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const config = await fetchProjectsConfig();

        // Verifica que config no sea undefined
        if (config && config.activeProjects) {
          const charityProjects = config.activeProjects.filter((project: Project) => project.isDonating);
          setCharityProjects(charityProjects);
        } else {
          console.error('Config is undefined or does not have activeProjects');
        }
      } catch (error) {
        console.error('Error fetching projects configuration:', error);
      }
    };

    loadProjects();
  }, []);

  const handleShowMore = () => {
    setVisibleProjects(prev => prev + 3);
  };

  return (
    <Box 
      w="100vw" // Asegura que el fondo negro ocupe todo el ancho de la vista
      px={4} 
      py={10} 
      bg="black" // Fondo negro
      color="white" // Color de texto blanco
    >
      <Box maxW="1200px" mx="auto" textAlign="center">
        <Heading as="h2" fontSize={{ base: '3xl', md: '5xl' }} mb={10} color="white">
          Proyectos Solidarios
        </Heading>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
          {charityProjects.slice(0, visibleProjects).map((project: Project) => (
            <CharityCard key={project.id} project={project} />
          ))}
        </Grid>

        <Box textAlign="center" mt={8}>
          {visibleProjects < charityProjects.length && (
            <Button onClick={handleShowMore} colorScheme="teal" variant="solid" mb={4}>
              Ver más
            </Button>
          )}
          <GradientButton onClick={() => window.location.href = '/proyectos'}>
            Ver Proyectos
          </GradientButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CharitySection;
