import React from 'react';
import { Box, Heading, Text, VStack, Divider } from '@chakra-ui/react';

const AboutUsPage: React.FC = () => {
  return (
    <>
      <Box maxW="1200px" mx="auto" py={10} px={4} mt={20}>
        <Heading as="h2" mb={8} textAlign="center" fontSize="3xl">
          Quiénes Somos
        </Heading>

        <VStack spacing={8} align="start">
          <Box>
            <Heading as="h3" mb={4} fontSize="2xl">
              Nuestra Misión
            </Heading>
            <Text fontSize="lg" lineHeight="1.8">
              Ofrecer los mejores servicios de tokenización y al menor coste posible para que, de esta forma, no haya nadie que, estando interesado, no pueda participar de ello. Queremos llegar de una forma sencilla al mayor número de personas que, aun sin entender de qué se trata todo esto, podamos explicárselo por si pudiesen beneficiarse de alguna manera. Podrás desarrollar tu proyecto, podrás invertir tu dinero, podrás aprender con nosotros y participar de nuestra comunidad que siempre será muy activa con apoyar a causas sociales cercanas a nosotros y que a través de nuestra web podrás ver los resultados.
              <br /><br />
              Nuestra misión es poder enseñar, compartir, desarrollar y colaborar con todo aquel que quiera llevar a cabo un proyecto de tokenización. Desde el minuto uno podemos guiarte para conseguir todas las metas que necesites. Nuestra misión es poder explicar de la forma más sencilla por qué la tokenización en blockchain merece la pena y qué beneficios podría ofrecer. Nuestra misión es crear una comunidad sólida, orgullosa de participar de alguna de las maneras que TokenMeet puede ofrecerle. Nuestra misión es que seas un poco más feliz.
            </Text>
          </Box>

          <Divider />

          <Box>
            <Heading as="h3" mb={4} fontSize="2xl">
              Cómo Lo Conseguimos
            </Heading>
            <Text fontSize="lg" lineHeight="1.8">
              Solo hay una manera: hacer las cosas con cariño, humildad y paciencia. El equipo de TokenMeet no solo está preparado profesionalmente, sino que su propia personalidad define el lugar donde trabajan. Claro que esto es un negocio, pero nosotros no queremos solo trabajar sino que haya por medio una forma de conseguir que todo aquel que vino a interesarse por nosotros sienta nuestro aprecio. Creemos de corazón que solo somos un eslabón de una cadena que quiere llegar lejos y, estando juntos, compartiendo cosas, estaremos todos más completos.
              <br /><br />
              Vengas de donde vengas, sepas más o menos, siempre encontrarás en nosotros a la persona que te podrá guiar para conseguir lo que buscas. Podrás encontrar desde una conversación sencilla que te ayude a comprender hasta a los abogados, desarrolladores tecnológicos o expertos en marketing más preparados en blockchain y tokenización.
              <br /><br />
              Rápido me puse a aprender todo lo posible sobre esto, pero en aquel tiempo apenas había personas con las que pudiese compartir mis ideas. Conocí también las criptomonedas y todo lo que conllevan. Desde ese momento inicial hasta hoy, y espero que por siempre, siga siendo una etapa muy bonita de conocimiento. Veía el futuro de una forma diferente y quería compartirlo. Pasó el tiempo y poco a poco fui acercándome y conociendo cada vez a más personas que se interesaban por todo este ecosistema.
              <br /><br />
              En todo este tiempo, otras actividades no me permitían lanzarme a la creación del proyecto que tenía en mente hasta que en noviembre de 2023 decidí dar el paso y romper la soledad que me envolvía sobre este mundo totalmente nuevo para mí. Un sector desconocido, pero que me intrigaba. Así que empecé a hablar y escuchar con unos y con otros, intentando formar un equipo que pensara como yo, pero que estuviesen muy preparados profesionalmente para acometer el proyecto TokenMeet. Ahora ya, TokenMeet dispone del mejor equipo posible, profesional y humano, siempre con ganas de ilusionarse contigo. La idea de TokenMeet nace en el año 2017 cuando conocí la tecnología blockchain y los tokens. Lo primero que pensé es… ¡Guau, esto puede cambiar las cosas, puede ayudar a muchas personas a salir de su círculo vicioso de pobreza y tristeza. Puede ayudar a que individualmente controlemos más nuestras cosas y también podamos participar de una manera más profunda en las actividades de otros. Para mí, la tokenización de activos cada día rondaría más en la mente de empresas y personas.
            </Text>
          </Box>
        </VStack>
      </Box>
 
    </>
  );
};

export default AboutUsPage;
