import image1 from '../assets/images/Evento1.jpeg';
import image2 from '../assets/images/evento2.jpg';
import image3 from '../assets/images/evento3.jpg';
import image4 from '../assets/images/cover1.jpeg';

export const eventsConfig = [
  {
    id: 'presentacion-oficial-tokenmeet',
    title: 'Presentación Oficial TokenMeet',
    date: '2024-09-19',
    time: '20:00',
    location: 'Restaurante Almijara, Casual Bar, Pje. San Fernando, 3, Distrito Centro, 29002 Málaga',
    description: 'Te invitamos a una de nuestras presentaciones de TokenMeet para miembros relevantes de la prensa y del mundo empresarial. En el evento, conocerás de cerca nuestro proyecto, compartiremos nuestra misión y visión, y te mostraremos cómo estamos preparados para transformar el mundo de la tokenización en todos los sectores del mercado a través de nuestra comunidad global, todo ello en un ambiente relajado y cercano.',
    shortDescription: 'Presentación Oficial TokenMeet',
    image: image1,
    coverImage: image4,  // Imagen de portada opcional
    url: '/events/presentacion-oficial-tokenmeet'  // Ruta relativa
  },
  
];
