import React from 'react';
import { Box, Heading, Text, Button, ButtonProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import levelBackgroundImage from '../../assets/images/TOMT.webp';

const levels = [
  {
    name: 'Explorador',
    description: 'Comienza tu aventura en TokenMeet y desbloquea recompensas básicas. Ideal para quienes están empezando en el mundo de las criptomonedas.',
    colorOverlay: 'rgba(0, 0, 0, 0.3)', // Dark filter for Explorador with 30% opacity
    infoLink: '/tokenmeet-info'
  },
  {
    name: 'Junior',
    description: 'Avanza a Junior y disfruta de beneficios adicionales como acceso a contenido exclusivo y recompensas mejoradas.',
    colorOverlay: 'rgba(0, 0, 255, 0.3)', // Blue filter for Junior with 30% opacity
    infoLink: '/tokenmeet-info'
  },
  {
    name: 'Senior',
    description: 'El nivel Senior te abre las puertas a NFTs más valiosos y eventos exclusivos en la comunidad.',
    colorOverlay: 'rgba(0, 255, 0, 0.3)', // Green filter for Senior with 30% opacity
    infoLink: '/tokenmeet-info'
  },
  {
    name: 'Oro',
    description: 'Alcanza el nivel Oro para disfrutar de recompensas premium y acceso VIP a todos los eventos y recursos.',
    colorOverlay: 'rgba(255, 215, 0, 0.3)', // Gold filter for Oro with 30% opacity
    infoLink: '/tokenmeet-info'
  },
  {
    name: 'Platino',
    description: 'El nivel más alto. Disfruta de todos los privilegios, incluyendo acceso anticipado a las nuevas oportunidades y NFTs exclusivos.',
    colorOverlay: 'rgba(255, 255, 255, 0.3)', // Platinum filter for Platino with 30% opacity
    infoLink: '/tokenmeet-info'
  },
];

const GradientButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      size="md"
      fontSize="14px"
      bgGradient="radial-gradient(circle at 0% 80%, #0ef1fa 0%, #0b4d78 100%)"
      color="white"
      px={6}
      py={4}
      border="none"
      borderRadius="99px"
      _hover={{
        bgGradient: "radial-gradient(circle at 0% 50%, #0ef1fa 0%, #0b4d78 90%)",
      }}
      _active={{
        bgGradient: "radial-gradient(circle at 0% 50%, #0ef1fa 0%, #0b4d78 80%)",
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

const LevelCard: React.FC<{ level: { name: string; description: string; colorOverlay: string; infoLink: string; } }> = ({ level }) => {
  const navigate = useNavigate();

  const handleMoreInfoClick = () => {
    navigate(level.infoLink);
  };

  return (
    <Box
      borderRadius="12px"
      overflow="hidden"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
      bgImage={`url(${levelBackgroundImage})`}
      bgSize="cover"
      bgPos="center"
      width={{ base: '100%', md: '220px' }}
      height="350px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      position="relative"
      p="16px"
      m="0 auto"
      _hover={{ transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)' }}
    >
      <Box position="absolute" top="0" left="0" width="100%" height="100%" bg={level.colorOverlay} zIndex="0" />
      <Box position="absolute" top="0" left="0" width="100%" height="100%" bg="rgba(0, 0, 0, 0.4)" zIndex="1" /> {/* Background behind text */}
      <Box position="relative" zIndex="2">
        <Heading fontSize="lg" color="white" mb="4">{level.name}</Heading>
        <Text fontSize="sm" color="white">{level.description}</Text>
      </Box>
      <GradientButton
        mt="auto"
        zIndex="2"
        onClick={handleMoreInfoClick}
      >
        Más información
      </GradientButton>
    </Box>
  );
};

const LevelsSection: React.FC = () => {
  const navigate = useNavigate();

  const handleViewProjectsClick = () => {
    navigate('/proyectos');
  };

  return (
    <Box 
      w="100%" 
      py={10} 
      px={4}
      bgGradient="radial-gradient(circle at 0% 80%, #0ef1fa 0%, #0b4d78 100%)" // Fondo azul degradado
      color="white" // Texto blanco para el contraste
    >
     <Box maxW="1200px" mx="auto" py={10} px={4} textAlign="center">
      <Heading as="h2" fontSize="5xl" mb={10} color="white">
          ¡Únete a TokenMeet y Gana NFTs Exclusivos!
        </Heading>
        <Box display="grid" gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }} gap={6}>
          {levels.map((level, index) => (
            <LevelCard key={index} level={level} />
          ))}
        </Box>
        <Box textAlign="center" mt={10}>
          <GradientButton onClick={handleViewProjectsClick}>
            Ver Proyectos
          </GradientButton>
        </Box>
      </Box>
    </Box>
  );
};

export default LevelsSection;
