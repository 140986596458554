import React from 'react';
import { Box, Heading, Text, Image, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';  // Import Link de react-router-dom para manejar rutas relativas
import { Event } from '../../types/eventTypes';  // Asegúrate de que la ruta a Event es correcta

const EventCard: React.FC<{ event: Event }> = ({ event }) => {
  return (
    <Box
      borderRadius="8px"
      overflow="hidden"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      bg="white"
      transition="all 0.3s ease"
      _hover={{ transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }}
      maxW="100%"
      m="0 auto"
    >
      <Image
        src={event.image}
        alt={event.title}
        height="200px"
        width="100%"  // Asegúrate de que la imagen cubra el 100% del ancho
        objectFit="cover"
        borderBottom="6px solid #0b4d78"
      />
      <Box p="16px">
        <Heading fontSize="1.25rem" mb="8px">{event.title}</Heading>
        <Text fontSize="0.875rem" mb="8px">
          {new Date(event.date).toLocaleDateString()} - {event.time} - {event.location}
        </Text>
        <Text mb="16px">{event.shortDescription}</Text>
        <Link to={event.url}>
          <ChakraLink color="primary.500" fontWeight="bold">
            Ver Detalles
          </ChakraLink>
        </Link>
      </Box>
    </Box>
  );
};

export default EventCard;
