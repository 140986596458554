import React from 'react';
import { Box, Heading, Grid } from '@chakra-ui/react';
import { educationResourcesConfig } from '../../config/educationResourcesConfig';
import NewsCard from '../cards/NewsCard';  // Importar tarjeta de noticias
import GradientButton from '../buttons/GradientButton';  // Importar el componente GradientButton

const AcademySection: React.FC = () => {
  const latestNews = educationResourcesConfig.filter(resource => resource.type === 'news').slice(0, 3);
  const latestVideos = educationResourcesConfig.filter(resource => resource.type === 'video').slice(0, 3);

  return (
    <Box maxW="1200px" mx="auto" py={10} px={4} textAlign="center">
      <Heading as="h2" fontSize="5xl" mb={10} color="primary.600">
        Academy
      </Heading>

      <Heading as="h3" mb={4} fontSize="3xl" textAlign="left" color="primary.600">
        Últimas Noticias
      </Heading>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
        {latestNews.map(resource => (
          <NewsCard key={resource.id} resource={resource} />
        ))}
      </Grid>

      <Heading as="h3" mt={8} mb={4} fontSize="3xl" textAlign="left" color="primary.600">
        Últimos Videos
      </Heading>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
        {latestVideos.map(resource => (
          <Box
            key={resource.id}
            position="relative"
            paddingBottom="56.25%"  // Mantiene la proporción 16:9
            height="0"
            overflow="hidden"
          >
            <Box
              as="iframe"
              src={resource.videoUrl?.replace('youtu.be/', 'www.youtube.com/embed/')}
              title={resource.title}
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        ))}
      </Grid>

      <Box display="flex" justifyContent="center" mt={8} gap={4}>
        <GradientButton onClick={() => window.open('/academy', '_blank')}>
          Ver Todo
        </GradientButton>
        <GradientButton onClick={() => window.open('/projects', '_blank')}>
          Ver Proyectos
        </GradientButton>
      </Box>
    </Box>
  );
};

export default AcademySection;
