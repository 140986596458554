import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, Spinner, Flex } from '@chakra-ui/react';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import ProjectsPage from './pages/ProjectsPage';
import HomePage from './pages/HomePage';
import AllEventsPage from './pages/AllEventsPage';
import EventDetailPage from './pages/EventDetailPage';
import AcademyPage from './pages/AcademyPage';
import TokenMeetInfoPage from './pages/TokenMeetInfoPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditions from './pages/TermsAndConditions';
import AboutUsPage from './pages/AboutUsPage';
import { fetchProjectsConfig } from './config/projectsConfig';
import { Project } from './types/projectTypes';
import ScrollToTop from './components/ScrollToTop'; // Asegúrate de que la ruta sea correcta

function App() {
  const [projectsConfig, setProjectsConfig] = useState<{
    activeProjects: Project[];
    upcomingProjects: Project[];
    studyProjects: Project[];
  } | null>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProjects = async () => {
      if (window.location.pathname === '/' || window.location.pathname.includes('projects')) {
        const config = await fetchProjectsConfig();
        setProjectsConfig(config);
      }
      setLoading(false);
    };

    loadProjects();
  }, []);

  if (loading) {
    return (
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        bg="white"
      >
        <Spinner size="xl" color="blue.500" />
        <Box mt={4} fontSize="lg" color="gray.500">
          Cargando...
        </Box>
      </Flex>
    );
  }

  return (
    <Router>
      <ScrollToTop /> {/* Esto se asegura de que el scroll se restablezca al cambiar de ruta */}
      <Navbar />
      <Routes>
        <Route 
          path="/" 
          element={
            projectsConfig ? (
              <HomePage projectsConfig={projectsConfig} />
            ) : (
              <div>Loading...</div>
            )
          }
        />
        <Route 
          path="/projects" 
          element={
            projectsConfig ? (
              <ProjectsPage projectsConfig={projectsConfig} />
            ) : (
              <div>Loading...</div>
            )
          }
        />
        <Route path="/events" element={<AllEventsPage />} />
        <Route path="/events/:eventId" element={<EventDetailPage />} />
        <Route path="/academy" element={<AcademyPage />} />
        <Route path="/tokenmeet-info" element={<TokenMeetInfoPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route 
          path="/servicios" 
          element={
            projectsConfig ? (
              <HomePage projectsConfig={projectsConfig} />
            ) : (
              <div>Loading...</div>
            )
          } 
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
