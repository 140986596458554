import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: {
      500: '#0b4d78', // Nuevo color personalizado en lugar de teal.500
      600: '#0b4d78', // Nuevo color personalizado en lugar de teal.600
    },
  },
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Montserrat', sans-serif`,
  },
});

export default theme;
