import React from 'react';
import { Box, Image, Heading, Text } from '@chakra-ui/react';
import { Project } from '../../types/projectTypes';
import ProgressBar from '../../components/progressbars/ProgressBar';
import GradientButton from '../buttons/GradientButton';

const CharityCard: React.FC<{ project: Project }> = ({ project }) => {
  // Función para formatear números sin decimales
  const formatNumber = (num: number) => {
    return num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  // Calcula la cantidad recaudada hasta ahora
  const donationAmount = project.isDonating
    ? formatNumber((project.tokensIssued! * project.tokenPrice!) * (project.donationPercentage! / 100))
    : null;

  // Calcula el monto total que se espera recaudar para la caridad
  const totalDonationAmount = project.isDonating
    ? formatNumber((project.totalTokens * project.tokenPrice!) * (project.donationPercentage! / 100))
    : null;

  return (
    <Box
      borderRadius="8px"
      overflow="hidden"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      bg="white"
      transition="all 0.3s ease"
      _hover={{ transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }}
      maxW="100%"
      m="0 auto"
      pb="20px"
      display="flex"
      flexDirection="column"
      minHeight="400px"
    >
      <Image
        src={project.charityImage}
        alt={project.charityTitle}
        height="200px"
        width="100%"
        objectFit="cover"
        borderBottom="4px solid #319795"
      />
      <Box p="16px" flex="1" display="flex" flexDirection="column">
        <Box flex="1">
          <Heading fontSize="1.25rem" mb="8px" textAlign="left" color="gray.800">
            {project.charityTitle}
          </Heading>
          <Text fontSize="0.875rem" mb="8px" textAlign="left" color="gray.700">
            {project.charityDescription}
          </Text>
          {project.isDonating && (
            <Text fontSize="1em" mb="8px" textAlign="left" color="gray.700">
              <strong>Acción solidaria promovida por {project.promoter}</strong>
            </Text>
          )}
          {donationAmount && totalDonationAmount && (
            <Text fontSize="1em" mb="8px" textAlign="left" color="gray.700">
              <strong>Capital recaudado:</strong> ${donationAmount}/ ${totalDonationAmount}
            </Text>
          )}
        </Box>
        {project.tokensIssued !== undefined && (
          <Box mt="auto">
            <ProgressBar tokensIssued={project.tokensIssued} totalTokens={project.totalTokens} />
          </Box>
        )}
        {project.charityUrl && (
          <Box textAlign="center" mt={10}>
            <GradientButton onClick={() => window.open(project.charityUrl, '_blank')}>
              Visitar el proyecto
            </GradientButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CharityCard;
