import React from 'react';
import { Box, Flex, Text, VStack, useMediaQuery } from '@chakra-ui/react';
import videoBg from '../../assets/images/video_bg.jpeg'; // Ruta correcta
import GradientButton from '../../components/buttons/GradientButton'; // Importar el botón

const VideoSection: React.FC = () => {
  const [isSmallScreen] = useMediaQuery("(max-width: 1156px)");

  return (
    <Box
      width="100%"
      backgroundImage={`url(${videoBg})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      position="relative"
      overflow="hidden"
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="315px"
      margin="0"
      padding={isSmallScreen ? "4" : "10"}
    >
      {/* Flex principal que contiene el video, texto y botón */}
      <Flex
        direction="column" // Vertical en ambas vistas (móvil y escritorio)
        align="center"
        justify="center"
        width="100%"
        maxW="1200px"
        padding={isSmallScreen ? "2" : "4"}
        zIndex="1"
        gap={10}
      >
        {/* Box transparente que contiene el video y el texto */}
        <Box
          width="100%"
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}  // En móvil apilado, en escritorio lado a lado
          justifyContent="center"
          alignItems="center"
          background="rgba(0, 0, 0, 0)" // Fondo transparente
        >
          {/* Video */}
          <Box
            flex="1"
            width="100%"
            height={isSmallScreen ? "auto" : "315px"}
            paddingTop={isSmallScreen ? "56.25%" : "0"}  // Proporción correcta en móvil
            position="relative"
          >
            <Box
              as="iframe"
              src="https://www.youtube.com/embed/yLsSy64xILI?autoplay=1&mute=1&loop=1&playlist=yLsSy64xILI"
              title="YouTube video player"
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </Box>

          {/* Texto */}
          <Box
            flex="1"
            p={isSmallScreen ? "2" : "4"}
            position="relative"
            color="white"
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              background="rgba(0, 0, 0, 0)"
              backdropFilter="blur(10px)"
              zIndex="-1"
            />
            <VStack align="start" spacing={4}>
              <Text fontSize="lg" fontWeight="bold">
                Como vale más una imagen que mil palabras con este corto os queríamos trasladar nuestra filosofía.
              </Text>
              <Text fontSize="lg">
                Somos muy buenos haciendo tecnología, creando comunidad, asesorándote legalmente, desarrollando tus proyectos, ayudándote en todo y llevando tu ilusión a su punto final. Y para ello necesitamos de tu tiempo.
              </Text>
              <Text fontSize="lg">
                Pero por favor... No olvides nunca a tu familia, a tus amigos, a tu pareja, a las personas que te rodean y a las que no. Una sonrisa, una palabra de agradecimiento. Quizás sea el momento de la historia en el que más lo necesitamos.
              </Text>
              <Text fontSize="lg">
                Dediquemos tiempo a lo importante.
              </Text>
            </VStack>
          </Box>
        </Box>

        {/* Botón de Ver Proyectos debajo del contenido */}
        <Box textAlign="center" mt={10} width="100%">
          <GradientButton onClick={() => window.open('/projects', '_blank')}>
            Ver Proyectos
          </GradientButton>
        </Box>
      </Flex>
    </Box>
  );
};

export default VideoSection;
