import React from "react";
import { Box, Text } from "@chakra-ui/react";

interface ProgressBarProps {
  tokensIssued: number;
  totalTokens: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ tokensIssued, totalTokens }) => {
  const progress = Math.ceil((tokensIssued / totalTokens) * 100);

  return (
    <Box mt="16px">
      <Box 
        border="1px solid black" 
        borderRadius="99px" 
        position="relative" 
        height="1.8em"
        bg="white"
        display="flex"
        alignItems="center"
      >
        <Box
          bgGradient="radial-gradient(circle at 0% 80%, #0ef1fa 0%, #0b4d78 100%)"
          height="100%"
          width={`${progress}%`}
          borderRadius="99px"
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {progress > 20 && (
            <Text
              color="white"
              fontSize="1em"
              zIndex="1"
              pointerEvents="none"
            >
              {progress}%
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressBar;
