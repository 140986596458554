import image1 from '../assets/images/piamont.png';
import image2 from '../assets/images/Zentral.jpg';
import charityImage1 from '../assets/images/charity1.jpg';
import charityImage2 from '../assets/images/charity2.jpg';
import upcomingImage1 from '../assets/images/nani_boronat.jpeg';  
import upcomingImage2 from '../assets/images/Gibraltar Wave FC.png'; 
import studyImage1 from '../assets/images/piamont.png';        
import studyImage2 from '../assets/images/Zentral.jpg';       
import { contractABI } from './contractConfig';
import { Project } from '../types/projectTypes';
import { ethers } from 'ethers';

export const fetchProjectsConfig = async (): Promise<{ 
  activeProjects: Project[], 
  upcomingProjects: Project[], 
  studyProjects: Project[] 
}> => {

  // Función para obtener el total supply desde el contrato del proyecto
  const fetchTokensIssued = async (contractAddress: string, contractABI: any) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider("https://sepolia-rollup.arbitrum.io/rpc");
      const contract = new ethers.Contract(contractAddress, contractABI, provider);
      const totalSupply = await contract.totalSupply();
      return parseFloat(ethers.utils.formatUnits(totalSupply, 18));
    } catch (error) {
      console.error(`Error fetching total supply for contract ${contractAddress}:`, error);
      return 0;
    }
  };
  return {
    activeProjects: [
      {
        id: '1',
        title: 'Hotel 5 Estrellas Gran Lujo Piamont Residences (Saluyita, Puerto Vallarta, México)',
        promoter: 'BGA',
        image: image1,
        category: 'Real Estate',
        description: 'Piamont ofrece servicios de lujo y una excelente ubicación, asegurando altos retornos de inversión mediante ingresos por alquiler y apreciación de la propiedad.',
        isActive: true,
        totalTokens: 642,
        tokensIssued: await fetchTokensIssued('0xFD08870348a6913154E75087a054d1fb439063e5', contractABI),
        contractAddress: '0xFD08870348a6913154E75087a054d1fb439063e5',
        contractABI: contractABI,
        isDonating: true,
        charityImage: charityImage1,
        donationPercentage: 1,
        tokenPrice: 1000,
        charityTitle: 'Donación para la Educación en Saluyita',
        charityDescription: 'Contribuye al futuro de los niños en Saluyita con tu inversión. Un porcentaje de los ingresos se destina a becas para educación.',
        projectUrl: 'https://bga.token-meet.com/sale/0xbdd154df41b21587531668935b0e5e1d5fb1bc3b',
        charityUrl: 'https://charity-saluyita.org/donacion-educacion'
      },
      {
        id: '2',
        title: 'Apartamentos de Lujo Zentral (Guadalajara - México)',
        promoter: 'BGA',
        image: image2,
        category: 'Real Estate',
        description: 'Frente a la moderna área residencial Puerta de Hierro, con centros comerciales y un distrito de negocios, se encuentra un destino exclusivo.',
        isActive: true,
        totalTokens: 292,
        tokensIssued: await fetchTokensIssued('0xB036Eed37e4a220F8c73E97C3CFd8ef31390477C', contractABI),
        contractAddress: '0xB036Eed37e4a220F8c73E97C3CFd8ef31390477C',
        contractABI: contractABI,
        isDonating: true,
        charityImage: charityImage2,
        donationPercentage: 1,
        tokenPrice: 1000,
        charityTitle: 'Apoyo a Refugios en Guadalajara',
        charityDescription: 'Una parte de tu inversión ayudará a mantener y mejorar los refugios para personas sin hogar en Guadalajara.',
        projectUrl: 'https://bga.token-meet.com/sale/0xdd9f709262fe09082735835e15721fae0bf9da7c',
        charityUrl: 'https://refugios-guadalajara.org/apoyo'
      }
    ],
    upcomingProjects: [
      {
        id: '3',
        title: 'Colección NFTs Nani Boronat',
        promoter: 'Nani Boronat',
        image: upcomingImage1,
        category: 'Arte',
        description: 'Bienvenidos a NANI, la nueva aventura artística digital de un creador con más de 30 años de trayectoria. Explora su colección exclusiva en blockchain y únete a una comunidad global con acceso a sorteos, descuentos y eventos especiales. ¡Sé parte de esta revolucionaria visión creativa!',
        isActive: false,
        totalTokens: 500,
        tokensIssued: 0,
        contractAddress: '0x123456789abcdef0123456789abcdef01234567',
        contractABI: contractABI,
        isDonating: false,
        tokenPrice: 800,
        projectUrl: 'https://www.naniboronat.com/'
      },
      
      
    ],
    studyProjects: [
      {
        id: '4',
        title: 'Gibraltar Wave FC',
        promoter: 'Gibraltar Wave FC',
        image: upcomingImage2,
        category: 'Deportes',
        description: 'Si eres un verdadero fan del futbol no te puedes perder la collección de NFTs que va a emitir el Gibraltar Wave FC',
        isActive: false,
        totalTokens: 500,
        tokensIssued: 0,
        contractAddress: '0x123456789abcdef0123456789abcdef01234567',
        contractABI: contractABI,
        isDonating: false,
        tokenPrice: 800,
        projectUrl: 'https://www.gibraltarwavefc.com/'
      },
    ]
  };
};
