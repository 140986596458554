import React from 'react';
import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  VStack,
} from '@chakra-ui/react';

const FAQSection: React.FC = () => {
  const faqs = [
    {
      question: '¿Qué es la tokenización?',
      answer: 'La tokenización es el proceso de convertir un activo en un token digital que puede ser intercambiado en una blockchain. TokenMeet ofrece servicios de tokenización de activos para brindar nuevas oportunidades de inversión.',
    },
    {
      question: '¿Cómo funciona TokenMeet?',
      answer: 'TokenMeet es una plataforma que brinda asesoría legal y visibilidad a proyectos tokenizados. Ayudamos a los usuarios sin experiencia en tokenización a comprender y aprovechar las oportunidades que ofrece esta tecnología.',
    },
    {
      question: '¿Qué es TOMT?',
      answer: 'TOMT es el token propio de TokenMeet. Al ser parte de la comunidad, podrás beneficiarte de las ventajas y participar en eventos y proyectos exclusivos.',
    },
    {
      question: '¿Cómo puedo aprender sobre la tokenización?',
      answer: 'En la sección de Educación de TokenMeet encontrarás videos y artículos que te ayudarán a aprender sobre la tokenización y sus beneficios.',
    },
    {
      question: '¿Cómo puedo participar en eventos de TokenMeet?',
      answer: 'TokenMeet organiza eventos físicos donde personas con intereses similares pueden conectarse y aprovechar las nuevas oportunidades de negocio que ofrece la tokenización.',
    },
  ];

  return (
    <Box maxW="1200px" mx="auto" py={10} px={4} textAlign="center">
      <Heading as="h2" fontSize="5xl" mb={10} color="brand.600">
        Preguntas Frecuentes
      </Heading>
      <Accordion allowToggle>
        {faqs.map((faq, index) => (
          <AccordionItem key={index} borderRadius="md" mb={4} border="1px solid" borderColor="gray.200">
            <AccordionButton
              _expanded={{
                bgGradient: 'radial-gradient(circle at 0% 80%, #0ef1fa 0%, #0b4d78 100%)',
                color: 'white',
              }}
              p={4}
              borderRadius="md"
              _hover={{
                bgGradient: 'radial-gradient(circle at 0% 70%, #0ef1fa 0%, #0b4d78 100%)',
                color: 'white',
              }}
            >
              <Box flex="1" textAlign="left">
                <Heading as="h3" fontSize="lg">
                  {faq.question}
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text fontSize="md" color="gray.600">
                {faq.answer}
              </Text>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default FAQSection;
