import React from 'react';
import { Box, Heading, Text, Button, Link } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { eventsConfig } from '../config/eventsConfig';
import { FiCalendar, FiMapPin } from 'react-icons/fi';

const EventDetailPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const event = eventsConfig.find((e) => e.id === eventId);

  if (!event) {
    return <Box p="24px">Evento no encontrado.</Box>;
  }

  const addToCalendar = () => {
    const icsFileContent = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Your Organization//NONSGML v1.0//EN
BEGIN:VEVENT
UID:${event.id}
DTSTAMP:${new Date().toISOString().replace(/-|:|\.\d+/g, '')}
DTSTART:${new Date(event.date).toISOString().replace(/-|:|\.\d+/g, '')}
SUMMARY:${event.title}
DESCRIPTION:${event.description}
LOCATION:${event.location}
END:VEVENT
END:VCALENDAR
    `;
    const blob = new Blob([icsFileContent], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${event.title}.ics`;
    a.click();
  };

  return (
    <Box mt="70px">  {/* Ajusta mt para evitar que el navbar lo tape */}
      <Box
        width="100vw"
        height={{ base: '20vh', md: '30vh' }}
        backgroundImage={`url(${event.coverImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="white"
        textAlign="center"
        p="24px"
        position="relative"
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bg="rgba(0, 0, 0, 0.5)"
          p="8px 16px"
          borderRadius="8px"
        >
          <Heading as="h1" size="2xl">
            {event.title}
          </Heading>
        </Box>
      </Box>
      <Box maxW="800px" m="0 auto" p="24px">
        <Text fontSize="xl" mb="8px">
          <strong>Fecha:</strong> {new Date(event.date).toLocaleDateString()} {event.time ? `- ${event.time}` : ''}
        </Text>
        <Text fontSize="xl" mb="8px"><strong>Ubicación:</strong> {event.location}</Text>
        <Text fontSize="lg" mb="16px">{event.description}</Text>

        <Button leftIcon={<FiCalendar />} colorScheme="teal" variant="solid" onClick={addToCalendar} mb="16px">
          Añadir al Calendario
        </Button>

        <Box mt="24px">
          <Heading size="md" mb="8px">Ubicación del Evento</Heading>
          <Box
            as="iframe"
            src={`https://www.google.com/maps?q=${encodeURIComponent(event.location)}&output=embed`}
            width="100%"
            height="300px"
            border="0"
            borderRadius="8px"
            mb="16px"
          />
        </Box>

        <Link
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.location)}`}
          isExternal
          color="teal.500"
          fontWeight="bold"
        >
          <Button leftIcon={<FiMapPin />} colorScheme="teal" variant="outline">
            Ver en Google Maps
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default EventDetailPage;
