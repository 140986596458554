import React from 'react';
import { Box, Heading, Grid, Image, Link, Text } from '@chakra-ui/react';  // Import Text from Chakra UI
import { eventsConfig } from '../config/eventsConfig';

const AllEventsPage: React.FC = () => {
  return (
    <Box maxW="800px" mx="auto" py={10} px={4}>
      <Heading as="h2" fontSize="3xl" mb={6} mt={20} textAlign="center" color="primary.600">Todos los Eventos</Heading>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
        {eventsConfig.map((event) => (
          <Box key={event.id} borderRadius="8px" overflow="hidden" boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)" bg="white">
            <Image src={event.image} alt={event.title} height="200px" objectFit="cover" />
            <Box p="16px">
              <Heading fontSize="1.25rem" mb="8px">{event.title}</Heading>
              <Text mb="8px">{new Date(event.date).toLocaleDateString()}</Text> {/* Correct usage of Text */}
              <Text mb="8px">{event.location}</Text> {/* Correct usage of Text */}
              <Link href={event.url} color="primary.500" fontWeight="bold">Ver detalles</Link>
            </Box>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default AllEventsPage;
